<template>

  <section id="dashboard" class="mt-4">

    <div class="container">
        <div class="row mt-5">

            <SideNav/>

            <div class="col-12 mt-sm-3 mt-md-0">

                      <table class="table mt-5">
                        <thead>
                          <tr>
                            <th scope="col">Username</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Completed</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="transaction in transactions" v-bind:key="transaction.user">
                            <td>{{transaction.userName}}</td>
                            <td>£{{transaction.amount}}</td>
                            <td>{{transaction.completed}}</td>
                            <td>{{transaction.date}}</td>
                          </tr>
                        </tbody>
                      </table>

            </div>
        </div>
    </div>
  </section>

  <Footer/>
</template>

<script>

import Api from '../../scripts/api'

import Footer from '../../components/global/Footer'
import SideNav from '../../components/admin/SideNavigator'

export default {
    components: {
        Footer,
        SideNav
    },
    props: {
      account: Object
    },
    data() {
      return {
        transactions: undefined
      }
    },
    async created() {
      var result = await Api.admin.ordersGetAll();
      if(result.status == 200) {
        this.transactions = await result.json();
      } else {
        this.$router.push('/');  
      }
    },
}
</script>